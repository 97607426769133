export function hasTokensInHash(hash) {
    return /((id|access)_token=)/i.test(hash);
}
// authorization_code
export function hasAuthorizationCode(hashOrSearch) {
    return /(code=)/i.test(hashOrSearch);
}
// interaction_code
export function hasInteractionCode(hashOrSearch) {
    return /(interaction_code=)/i.test(hashOrSearch);
}
export function hasErrorInUrl(hashOrSearch) {
    return /(error=)/i.test(hashOrSearch) || /(error_description)/i.test(hashOrSearch);
}
export function isRedirectUri(uri, sdk) {
    var authParams = sdk.options;
    return uri && uri.indexOf(authParams.redirectUri) === 0;
}
export function isCodeFlow(options) {
    return options.pkce || options.responseType === 'code' || options.responseMode === 'query';
}
export function getHashOrSearch(options) {
    var codeFlow = isCodeFlow(options);
    var useQuery = codeFlow && options.responseMode !== 'fragment';
    return useQuery ? window.location.search : window.location.hash;
}
/**
 * Check if tokens or a code have been passed back into the url, which happens in
 * the OIDC (including social auth IDP) redirect flow.
 */
export function isLoginRedirect(sdk) {
    // First check, is this a redirect URI?
    if (!isRedirectUri(window.location.href, sdk)) {
        return false;
    }
    // The location contains either a code, token, or an error + error_description
    var codeFlow = isCodeFlow(sdk.options);
    var hashOrSearch = getHashOrSearch(sdk.options);
    if (hasErrorInUrl(hashOrSearch)) {
        return true;
    }
    if (codeFlow) {
        var hasCode = hasAuthorizationCode(hashOrSearch) || hasInteractionCode(hashOrSearch);
        return hasCode;
    }
    // implicit flow, will always be hash fragment
    return hasTokensInHash(window.location.hash);
}
/**
 * Check if error=interaction_required has been passed back in the url, which happens in
 * the social auth IDP redirect flow.
 */
export function isInteractionRequired(sdk, hashOrSearch) {
    if (!hashOrSearch) { // web only
        // First check, is this a redirect URI?
        if (!isLoginRedirect(sdk)) {
            return false;
        }
        hashOrSearch = getHashOrSearch(sdk.options);
    }
    return /(error=interaction_required)/i.test(hashOrSearch);
}
